<template>
  <div class="terms">
    <v-btn
      style="text-transform: none"
      @click="$router.go(-1)"
      color="success"
      elevation="0"
      width="140"
      class="mt-8"
      ><v-icon small class="mr-2">mdi-arrow-left</v-icon> Back</v-btn
    >
    <v-card
      tile
      flat
      class="mt-10 warning--text"
      style="padding: 70px"
      width="50%"
      max-width="1200"
      min-width="800"
    >
      <v-img
        contain
        width="140"
        class="mx-auto mb-6"
        :src="require('@/assets/logo.svg')"
      ></v-img>
      <h1>Terms & conditions</h1>
      <p class="mt-4">
        Welcome to the Brainsight.app (“Brainsight”, “us” “we” or “our”)
        website. Please read these Terms of Service (the “Terms”) carefully
        because they govern the use of our websites and Brainsight as a product
        and services (“Services”).
      </p>
      <v-divider class="my-6"></v-divider>
      <div class="article" v-for="article in terms" :key="article.id">
        <h3 class="mb-3">{{ article.id }}. {{ article.title }}</h3>
        <p v-for="(text, index) in article.text" :key="index">
          {{ text }}
        </p>
      </div>
    </v-card>
  </div>
</template>

<script>
import terms from "./terms";

export default {
  name: "TermsConditions",
  data() {
    return {
      terms: terms,
    };
  },
};
</script>

<style lang="scss" scoped>
.terms {
  display: flex;
  width: 100vw;
  height: 100%;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
}
</style>
