<template>
  <div class="workspace-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo.svg" width="50" alt="" />
        <span><b>brainsight</b></span>
      </div>
      <div v-if="userProfile.role != 'trial'">
        <h1>Invite your team</h1>
        <p class="subtitle">
          For optimal team collaboration. If you have a subscription with more
          than 5 seats, you can add more later.
        </p>
        <div class="form">
          <div v-for="(invite, index) in invites" :key="index">
            <v-text-field
              filled
              label="name@yourcompany.com"
              background-color="white"
              type="text"
              hide-details="true"
              class="mb-5"
              rounded
              v-model="invite.email"
            ></v-text-field>
          </div>
          <v-btn
            @click="sendInvites"
            block
            rounded
            elevation="0"
            height="56"
            color="success"
            class="mt-7"
            >Finish!</v-btn
          >
          <p @click="$router.push('/home')" class="skip"><u>Skip</u></p>
        </div>
      </div>
      <div v-else>
        <h1>Invite & extend trial</h1>
        <p class="subtitle mb-10">
          Try Deepgaze together with your team and we'll extend the trial period
          with 7 days.
        </p>
        <div class="form">
          <div class="row" v-for="(invite, index) in invites" :key="index">
            <v-text-field
              filled
              label="name"
              background-color="white"
              type="text"
              hide-details="true"
              rounded
              style="max-width: 250px"
              v-model="invite.email"
            ></v-text-field>
            <span>@{{ domain }}</span>
          </div>
          <v-btn
            @click="sendInvites"
            block
            rounded
            elevation="0"
            height="56"
            color="success"
            class="mt-7"
            >Finish!</v-btn
          >
          <p @click="$router.push('/home')" class="skip"><u>Skip</u></p>
        </div>
      </div>
      <div class="terms"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "InvitesPage",
  data() {
    return {
      checkbox: false,
      find: "",
      invites: [
        {
          email: "",
        },
        {
          email: "",
        },
        {
          email: "",
        },
        {
          email: "",
        },
      ],
      items: ["Colleague", "Linkedin", "Advertisement", "Friend"],
      name: "",
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
    }),
    domain() {
      const domain = this.userProfile.email.split("@");
      return domain[1];
    },
  },
  methods: {
    sendInvites() {
      this.invites.forEach((i) => {
        console.log(i);
        // Add emails to be send  (first implement mandrill api)
      });
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.workspace-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #282447;

  .row {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
  }

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 50%;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
      margin-bottom: 30px;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }

    .skip {
      text-align: center;
      margin-top: 10px;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
