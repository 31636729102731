<template>
  <div class="login-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo.svg" width="50" alt="" />
        <span><b>brainsight</b></span>
      </div>
      <div>
        <v-btn
          fab
          class="mb-5"
          elevation="0"
          small
          color="white"
          @click="$router.push('/login')"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        <h1>Password reset</h1>
        <p v-if="resetMode" class="subtitle">
          Enter your email to reset your password:
        </p>
        <p class="subtitle" v-if="!resetMode">
          An email has been sent to reset your password.
        </p>
        <v-text-field
          v-if="resetMode"
          label="email"
          hide-details="true"
          class="my-5"
          type="email"
          rounded
          filled
          background-color="white"
          color="primary"
          v-model="email"
        ></v-text-field>
        <v-btn
          v-if="resetMode"
          @click="reset"
          elevation="0"
          color="success"
          rounded
          height="56"
          block
          >Reset password</v-btn
        >
      </div>
      <div class="terms"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import firebase from "firebase/";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
      resetMode: true,
    };
  },
  methods: {
    reset() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.resetMode = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 50%;
    max-width: 500px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
    }

    .google {
      margin-top: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: white;
      padding: 13px 0;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 3px rgba(209, 219, 229, 1);
      }
      img {
        width: 30px;
      }
    }

    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      span {
        text-align: center;
        font-size: 10pt;
      }
    }
    .forgot {
      color: #8b78fa;
      margin-top: 10px;
      text-align: end;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }

    .terms {
      text-align: center;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
