<template>
  <div class="login-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo.svg" width="50" alt="" />
        <span><b>brainsight</b></span>
      </div>
      <div>
        <h1>Verify your email</h1>
        <p class="subtitle">
          We've sent you an email to verify your account. Please click the link
          in the email to continue.
        </p>
        <p class="subtitle">
          If you don't see it within 30 seconds, please <u>check your spam.</u>
        </p>
        <v-btn
          large
          outlined
          rounded
          color="warning"
          elevation="0"
          @click="verify"
          >Send verification email again</v-btn
        >
        <p class="mt-3 primary--text font-weight-medium" v-if="sent > 1">
          Email on the way!
        </p>
      </div>
      <div class="terms"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import firebase from "firebase/";
import { db, auth } from "@/firebase";
export default {
  name: "VerifyAccount",
  data() {
    return {
      email: "",
      name: "",
      password: "",
      res: null,
      sent: 1,
      workspace: "",
    };
  },
  async mounted() {
    if (auth.currentUser.emailVerified) {
      this.$router.push("/dashboard");
    } else {
      this.verify();
    }
  },
  methods: {
    async verify() {
      var verify = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("verify");
      const link = await verify();
      if (link != 500) {
        const vm = this;
        const email = auth.currentUser.email;
        db.collection("mail")
          .add({
            to: email,
            from: "Brainsight <info@brainsight.app>",
            template: {
              name: "verify",
              data: {
                url: link.data,
              },
            },
          })
          .then(function (docRef) {
            vm.sent++;
            console.log(`Email sent to ${email} with id ${docRef.id}`);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$router.push("/dashboard");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 50%;
    max-width: 500px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
    }

    .google {
      margin-top: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: white;
      padding: 13px 0;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 3px rgba(209, 219, 229, 1);
      }
      img {
        width: 30px;
      }
    }

    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      span {
        text-align: center;
        font-size: 10pt;
      }
    }
    .forgot {
      color: #8b78fa;
      margin-top: 10px;
      text-align: end;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }

    .terms {
      text-align: center;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
