<template>
  <div class="followup-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo.svg" width="50" alt="" />
        <span><b>brainsight</b></span>
      </div>
      <div>
        <h1>Hi, {{ userProfile.firstName }} &#128075;</h1>
        <p class="subtitle">Nice to meet you!</p>
        <div class="form">
          <v-select
            :items="items"
            label="How did you find us?"
            rounded
            variant="solo"
            flat
            bg-color="white"
            v-model="find"
            hide-details=""
            prepend-inner-icon="mdi-magnify"
            :menu-props="{ rounded: true }"
          ></v-select>
          <v-select
            :items="fields"
            label="In what field are you working?"
            class="mt-3"
            rounded
            variant="solo"
            flat
            bg-color="white"
            v-model="field"
            hide-details=""
            prepend-inner-icon="mdi-magnify"
            :menu-props="{ rounded: true }"
          ></v-select>
          <v-text-field
            rounded
            variant="solo"
            flat
            bg-color="white"
            label="Company name"
            type="text"
            hide-details="true"
            class="mb-5 mt-3"
            v-model="company"
            prepend-inner-icon="mdi-domain"
          ></v-text-field>

          <v-btn
            class="mt-5"
            @click="update"
            block
            rounded
            elevation="0"
            height="56"
            color="success"
            >Continue</v-btn
          >
        </div>
      </div>
      <div class="terms"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { db, auth } from "@/firebase";
export default {
  name: "FollowUp",
  data() {
    return {
      checkbox: false,
      company: "",
      field: "",
      fields: [
        "CRO",
        "Display Advertising / Performance Marketing",
        "Other",
        "Social media (advertising)",
        "UX",
      ],
      find: "",
      items: [
        "Advertisement",
        "Google/search",
        "Linkedin/Facebook",
        "Referred by friend / colleague",
        "Other",
      ],
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
    }),
  },
  methods: {
    update() {
      const vm = this;
      var userRef = db.collection("users").doc(auth.currentUser.uid);

      return userRef
        .update({
          company_name: this.company,
          find: this.find,
          followedUp: true,
          field: this.field,
        })
        .then(() => {
          vm.$store.dispatch("fetchUserProfile", this.userProfile);
          vm.$router.push("/");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.followup-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 50%;
    max-width: 500px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
      margin-bottom: 30px;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
