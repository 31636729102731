<template>
  <v-app @dragenter="show = true" style="background-color: #ebebef">
    <FileDrop text="test" @hide="show = false" :show="show" />
    <Notification />
    <AdminNav v-if="!$route.meta.noNav && $route.meta.requiresSuperadmin" />

    <v-overlay
      opacity="0.8"
      permanent
      :model-value="loading"
      :z-index="$route.name === 'Landing' ? 0 : 100"
      class="align-center justify-center"
      scrim="dark"
      v-if="$route.meta.overlay"
    >
      <v-img
        class="mb-5"
        contain
        max-width="40px"
        :src="require('@/assets/brand/logo_mark_white.svg')"
      ></v-img>
      <v-progress-linear
        rounded
        style="width: 40px"
        color="smoke"
        indeterminate
      ></v-progress-linear>
      <!-- <p class="mt-2" style="font-size: 10pt">Pre-heating ...</p> -->
    </v-overlay>
    <Drawer :drawer="drawer" ref="drawer" v-if="screen === 'desktop'" />
    <UploadCard />

    <div>
      <v-system-bar
        v-if="!$route.meta.noNav && !$route.meta.requiresSuperadmin"
        color="primary"
        height="30"
        class="bar white--text"
        style="font-size: 10pt; z-index: 99"
      >
        <Transition mode="out-in" name="slide-up">
          <div v-if="!generating">
            &#128640; Drop files to upload in the app!
          </div>
          <div v-else>
            <v-progress-linear
              rounded
              style="width: 100px"
              color="smoke"
              indeterminate
            ></v-progress-linear></div
        ></Transition>
        <!-- <v-btn @click="test">test</v-btn> -->
      </v-system-bar>
    </div>
    <v-app-bar
      id="appbar"
      v-if="!$route.meta.noNav && !$route.meta.requiresSuperadmin"
      height="70"
      flat
      color="dark"
      light
      style="z-index: 99"
      class="px-6 pt-2"
    >
      <div
        class="d-flex align-center px-5"
        style="cursor: pointer"
        @click="goTo('/dashboard', true)"
      >
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/brand/logo_mark_white.svg')"
          transition="scale-transition"
          width="30"
        />
      </div>

      <div class="menu">
        <v-tabs
          :show-arrows="false"
          v-model="tab"
          bg-color="transparent"
          :optional="optional"
        >
          <v-tab
            style="text-transform: none"
            v-for="item in menu"
            :key="item.route"
            @click="goTo(item.route, true)"
            >{{ item.text }}</v-tab
          >
          <v-menu
            v-if="
              workspace &&
              workspace.access &&
              workspace.access.includes('youtube_thumbnails')
            "
          >
            <template v-slot:activator="{ props }">
              <v-tab v-bind="props">
                <span>Tools </span>
              </v-tab>
            </template>
            <v-list density="compact" style="font-size: 10pt">
              <v-list-item
                v-if="workspace.access.includes('youtube_thumbnails')"
                @click="$router.push('/youtube')"
              >
                <v-list-item-title
                  >YouTube Thumbnail Template</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>

        <v-spacer></v-spacer>

        <div
          v-if="userProfile.role === 'trial'"
          style="display: flex; align-items: center; gap: px; font-size: 11pt"
          class="mr-10 white--text"
        >
          <u></u>
          <v-btn
            @click="goTo('/subscription', false)"
            small
            elevation="0"
            color="error"
            style="text-transform: none"
          >
            <span v-if="!trialEnded">
              <span v-if="daysLeft.type === 'days'">
                {{ daysLeft.value }} days
              </span>
              <span
                v-else-if="daysLeft.type === 'countdown' && daysLeft.value > 1"
              >
                {{ daysLeft.value }} hours
              </span>
              <span
                v-else-if="daysLeft.type === 'countdown' && daysLeft.value <= 1"
              >
                Less than 1 hour
              </span>
              of trial left
            </span>
            <span v-if="trialEnded">Your trial has ended!</span></v-btn
          >
        </div>
        <v-btn
          @click="goTo('/subscription', false)"
          v-if="
            subscription.active === false &&
            userProfile.role != 'trial' &&
            userProfile.role != 'freemium'
          "
          width="130"
          elevation="0"
          color="error"
          class="mr-3"
          style="text-transform: none"
          >Upgrade</v-btn
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              @click="$router.push('/workspace')"
              elevation="0"
              variant="tonal"
              prepend-icon="mdi-image-outline"
              style="font-size: 10pt"
              class="font-weight-regular"
              >{{
                userProfile.role === "member" || userProfile.role === "admin"
                  ? workspace.imageCredits
                  : userProfile.imageCredits
              }}</v-btn
            >
          </template>
          <span>Image heatmap credits left.</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="tonal"
              @click="$router.push('/workspace')"
              elevation="0"
              style="font-size: 10pt"
              class="font-weight-regular ml-2 mr-4"
              prepend-icon="mdi-video-outline"
              >{{
                userProfile.role === "member" || userProfile.role === "admin"
                  ? workspace.videoCredits
                  : userProfile.videoCredits
              }}</v-btn
            >
          </template>
          <span>Video heatmap credits left.</span>
        </v-tooltip>

        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ props }">
            <div class="user py-2" v-bind="props">
              <v-avatar
                :image="userProfile.avatar ? userProfile.avatar : null"
                :icon="userProfile.avatar ? null : 'mdi-account'"
                height="38"
                width="38"
                min-width="38"
                color="white"
              >
              </v-avatar>
              <img id="chevron" src="@/assets/icons/chevron-down.svg" alt="" />
            </div>
          </template>

          <v-list style="cursor: pointer" class="" min-width="190">
            <v-list-item
              v-for="(item, index) in userMenu"
              :key="index"
              @click="goTo(item.route, false)"
              :prepend-icon="item.icon"
            >
              {{ item.text }}
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-email-plus-outline"
              @click="$router.push('/support')"
            >
              Support
            </v-list-item>
            <v-list-item prepend-icon="mdi-logout" @click="logout">
              Log out
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main>
      <Upload :uploads="uploads" @dialog="setDialog" />
      <Error :errorProp="error" @dialog="setError" />
      <Message :messageProp="message" @message="setMessage" />
      <Tutorial />
      <v-snackbar
        rounded="pill"
        color="success"
        v-model="snackbar"
        :timeout="timeout"
        dark
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>

      <router-view v-slot="{ Component, route }">
        <transition name="slide-fade" mode="out-in">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </v-main>
    <Report :dialog="reportDialog" @dialog="setReport" />

    <!-- <VueFullScreenFileDrop
      @drop="onDrop"
      v-if="
        $route.name != 'Landing' &&
        $route.name != 'Features' &&
        $route.name != 'Pricing' &&
        $route.name != 'Blog' &&
        $route.name != 'Article' &&
        $route.name != 'Grid8'
      "
    >
      <div class="dropscreen">
        <h1>&#128293;&#128293;&#128293;</h1>
        <h1>Drop it like it's hot</h1>
        <p>Add files by dropping them here</p>
      </div>
    </VueFullScreenFileDrop> -->
  </v-app>
</template>

<script>
import { auth } from "@/firebase";
import { mapState } from "vuex";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import Upload from "@/components/upload/UploadComp.vue";
import Report from "@/components/reportWizard/ReportWizard.vue";
import Drawer from "@/components/drawer/DrawerCard.vue";
import Error from "@/components/error/ErrorCard.vue";
import Message from "@/components/message/MessageCard.vue";
import Tutorial from "@/components/tutorial/TutorialCard.vue";
import UploadCard from "@/components/uploadCard.vue";
import AdminNav from "@/components/adminNav/AdminNav.vue";
import firebase from "firebase/app";
import FileDrop from "@/components/fileDrop/FileDrop.vue";
import Notification from "@/components/Notification/NotificationCard.vue";

export default {
  name: "App",
  components: {
    Notification,
    FileDrop,
    AdminNav,
    UploadCard,
    VueFullScreenFileDrop,
    Upload,
    Report,
    Drawer,
    Error,
    Message,
    Tutorial,
  },
  data() {
    return {
      drawer: false,
      error: null,
      interval: null,
      intervalImage: null,
      items: [
        {
          text: "Details",
        },
        {
          text: "Areas of interest",
        },
        {
          text: "Comments",
        },
        {
          text: "Guides",
        },
        {
          text: "Compare",
        },
        {
          text: "Share",
          icon: "mdi-share",
        },
      ],
      message: {
        dialog: false,
        text: "",
        title: "",
        confetti: false,
        icon: "",
        buttons: [],
      },
      optional: false,
      reportDialog: false,
      selectedItem: null,
      show: false,
      snackbar: false,
      screen: "desktop",
      timeout: 4000,
      text: "Data saved!",
      tab: null,
      tab2: null,
      type: null,
      uploads: [],
      workspaceName: "",
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      scroll: (state) => state.scroll,
      projects: (state) => state.projects.projects,
      tags: (state) => state.heatmaps.tags,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
      reports: (state) => state.reports.reports,
      generating: (state) => state.uploads.loading,
    }),
    daysLeft() {
      // The user must be prohibited to upload new heatmaps if trial is ended
      const date1 = this.userProfile.trialEnd.toDate();
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const countdown = Math.ceil(diffTime / (1000 * 60 * 60));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return countdown < 24
        ? { type: "countdown", value: countdown }
        : { type: "days", value: diffDays };
    },
    trialEnded() {
      const date1 = this.userProfile.trialEnd.toDate();
      const date2 = new Date();
      return date2 > date1;
    },
    menu() {
      let menu = [
        {
          text: "Dashboard",
          route: "/dashboard",
        },
        {
          text: "Heatmaps",
          route: "/heatmaps",
        },
        {
          text: "Projects",
          route: "/projects",
        },
      ];

      if (
        this.userProfile.role === "trial" ||
        (this.subscription.active && this.subscription.tier === "business") ||
        (this.workspace.access && this.workspace.access.includes("reports"))
      ) {
        menu.push({
          text: "Reports",
          route: "/reports",
        });
      }
      return menu;
    },
    userMenu() {
      let userMenu = [
        {
          text: "My account",
          icon: "mdi-account-outline",
          route: "/account",
        },
      ];
      if (this.userProfile.workspace) {
        userMenu.push(
          {
            text: "Workspace",
            icon: "mdi-domain",
            route: "/workspace",
          },
          {
            text: "Subscription",
            icon: "mdi-credit-card-outline",
            route: "/subscription",
          }
        );
      } else if (
        this.userProfile.role === "trial" ||
        this.userProfile.role === "freemium"
      ) {
        userMenu.push({
          text: "Upgrade",
          icon: "mdi-credit-card-outline",
          route: "/subscription",
        });
      }

      if (this.userProfile.superadmin) {
        userMenu.push({
          text: "Super admin",
          icon: "mdi-account-supervisor-circle",
          route: "/superadmin",
        });
      }

      return userMenu;
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.path === "/dashboard") {
          this.tab = 0;
          this.selectedItem = null;
        } else if (val.path.includes("heatmap")) {
          this.tab = 1;
          this.selectedItem = null;
        } else if (val.path.includes("project")) {
          this.tab = 2;
          this.selectedItem = null;
        } else if (val.path.includes("report")) {
          this.tab = 3;
          this.selectedItem = null;
        } else {
          this.tab = null;
        }
      },
      deep: true,
      immediate: true,
    },

    userProfile: {
      handler: function () {},
      deep: true,
      immediate: true,
    },

    type() {
      if (this.type === "video") {
        if (this.items[1].text === "Areas of interest") {
          this.items.splice(1, 1);
        }
      } else if (
        this.type === "image" &&
        this.items[1].text != "Areas of interest"
      ) {
        this.items.splice(1, 0, { text: "Areas of interest" });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalImage);
  },
  async mounted() {
    await this.$store.dispatch("fetchDefaults");
  },
  methods: {
    async test() {
      console.log("test");
      var test = firebase.app().functions("europe-west1").httpsCallable("test");
      const res = await test();
      console.log(res.data);
    },
    allowed(file) {
      const videoLimit = this.userProfile.role === "trial" ? 61 : 901;

      function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
          case "jpg":
          case "gif":
          case "bmp":
          case "png":
          case "jpeg":
            return true;
        }
        return false;
      }

      function getExtension(filename) {
        var parts = filename.split(".");
        return parts[parts.length - 1];
      }

      function isVideo(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
          case "m4v":
          case "avi":
          case "mpg":
          case "mp4":
            // etc
            return true;
        }
        return false;
      }

      if (isVideo(file.name)) {
        var video = document.createElement("video");
        window.URL = window.URL || window.webkitURL;
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          var duration = video.duration;
          file.duration = duration;
          if (duration > videoLimit) {
            return false;
          } else {
            return true;
          }
        };
        video.src = URL.createObjectURL(file);
      } else if (isImage(file.name)) {
        return true;
      } else {
        return false;
      }
    },
    errorMessage(name) {
      const videoLimit =
        this.userProfile.role === "trial" ? "1 minute" : "15 minutes";

      this.uploads_dialog = false;
      this.setError({
        text: `Your file ${name} format/size is not accepted. The accepted formats are .JPG, .PNG and .MP4 (videos) with a maximum upload of ${videoLimit} per video.`,
        dialog: true,
      });
    },
    goBack() {
      history.back();
    },
    goTo(route, tabs) {
      if (this.$route.path != route) {
        if (!tabs) {
          this.optional = true;
          this.tab = null;
        } else {
          this.optional = false;
          this.tab = this.menu.findIndex((e) => e.route === route);
        }
        this.$router.push(route);
      } else {
        console.log("already there");
      }
    },
    async onDrop(formData, files) {
      console.log("droppign");
      function getExtension(filename) {
        var parts = filename.split(".");
        return parts[parts.length - 1];
      }

      function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
          case "jpg":
          case "gif":
          case "bmp":
          case "png":
          case "jpeg":
            //etc
            return true;
        }
        return false;
      }

      function isVideo(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
          case "m4v":
          case "avi":
          case "mpg":
          case "mp4":
            // etc
            return true;
        }
        return false;
      }

      let uploads = [];
      let imageCount = 0;
      let videoCount = 0;
      let notAllowed = 0;

      for (let f of files) {
        const uids =
          this.$route.name === "Project" ? [this.$route.params.id] : [];

        const allowed = await this.$store.dispatch("allowed", f);
        if (allowed === false) {
          notAllowed++;
          this.errorMessage(f.name);
        }

        if (isImage(f.name)) {
          const res = await this.toBase64(f);
          imageCount++;

          if (allowed) {
            uploads.push({
              original: f,
              type: "image",
              thumbnail: res,
              title: f.name,
              projectIds: uids,
              loading: false,
            });
          }
        } else if (isVideo(f.name)) {
          videoCount++;

          if (allowed) {
            uploads.push({
              original: f,
              type: "video",
              title: f.name,
              projectIds: uids,
              loading: false,
              duration: f.duration,
            });
          }
        }
      }

      // Check if user has enough credits to do these uploads .

      if (notAllowed === 0) {
        if (
          this.userProfile.role === "trial" ||
          this.userProfile.role === "freemium"
        ) {
          if (
            imageCount > this.userProfile.imageCredits ||
            (this.userProfile.imageCredits === 0 && imageCount > 0)
          ) {
            this.setError({
              text: "It seems you don't have enough Image credits",
              dialog: true,
            });
          } else if (
            videoCount > this.userProfile.videoCredits ||
            (this.userProfile.videoCredits === 0 && videoCount > 0)
          ) {
            this.setError({
              text: "It seems you don't have enough Video credits",
              dialog: true,
            });
          } else {
            this.uploads = uploads;
            this.dialog = true;
          }
        } else {
          if (
            imageCount > this.workspace.imageCredits ||
            (this.workspace.imageCredits === 0 && imageCount > 0)
          ) {
            this.setError({
              text: "It seems you don't have enough Image credits",
              dialog: true,
            });
          } else if (
            this.subscription.active === false &&
            !this.workspace.beta
          ) {
            this.setError({
              text: "It seems that your workspace doesn't have an active subscription.",
              dialog: true,
            });
          } else if (
            videoCount > this.workspace.videoCredits ||
            (this.workspace.videoCredits === 0 && videoCount > 0)
          ) {
            this.setError({
              text: "It seems you don't have enough Video credits",
              dialog: true,
            });
          } else {
            this.uploads = uploads;
            this.uploads_dialog = true;
          }
        }
      }
    },
    logout() {
      this.$store.dispatch("logout", auth.currentUser.uid);
    },
    setMessage(v) {
      this.message = v;
    },

    setDialog(val) {
      console.log("setting dialog to " + val);
      this.uploads_dialog = val;
      if (val === false) {
        this.uploads = [];
      }
    },
    setReport(val) {
      this.reportDialog = val;
    },
    setDrawer(v) {
      const child = this.$refs.drawer;
      child.activate(v);
    },
    setError(v) {
      this.error = v;
    },
    setHeatmapTab(val) {
      this.tab2 = val;
    },
    setSnackbar(val) {
      this.text = val.text;
      this.snackbar = true;
    },
    setType(val) {
      this.type = val;
    },
    setScreen(v) {
      this.screen = v;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "neue-haas-grotesk-display";
  src:
    url("./assets/fonts/neue-haas-grotesk-display-bold-regular.woff2")
      format("woff2"),
    url("./assets/fonts/neue-haas-grotesk-display-bold-regular.woff")
      format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "neue-haas-grotesk-display";
  src:
    url("./assets/fonts/neue-haas-grotesk-display-medium-regular.woff2")
      format("woff2"),
    url("./assets/fonts/neue-haas-grotesk-display-medium-regular.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "neue-haas-grotesk-display";
  src:
    url("./assets/fonts/neue-haas-grotesk-display-roman-regular.woff2")
      format("woff2"),
    url("./assets/fonts/neue-haas-grotesk-display-roman-regular.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}
// .v-application {
//   font-family: neue-haas-grotesk-display, sans-serif !important;
//   letter-spacing: 0.5px;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;

//   .title {
//     // To pin point specific classes of some components
//     font-family: neue-haas-grotesk-display, sans-serif !important;
//     letter-spacing: 0.5px;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
// }

.dropscreen {
  background-color: rgba($color: #282447, $alpha: 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}

.menu {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100vw;

  .menu-item {
    cursor: pointer;
  }

  a {
    color: white;
    text-decoration: none;

    &:active {
      font-weight: 700;
    }
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
  .profile {
    border: 1px solid white;
  }

  #chevron {
    transition: all 0.3s ease;
  }
  &:hover {
    #chevron {
      transform: rotate(90deg);
    }
  }
}

.appbar {
  background-color: #282447;
  background-image: -webkit-linear-gradient(
    -125deg,
    #282447 0%,
    #282447 50%,
    #ebebef 50%,
    #ebebef 50%
  );
  background-image: linear-gradient(
    -125deg,
    #282447 0%,
    #282447 50%,
    #ebebef 50%,
    #ebebef 50%
  );
}

.menu {
  .v-tab {
    font-weight: 400;
    font-size: 10pt;
  }
}

.bar {
  display: flex;
  justify-content: center;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(5px);
  opacity: 0;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>

<style lang="scss">
.myClass:focus::before {
  opacity: 0 !important;
}
</style>
